import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Filter from "../components/filter"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"

const content =
  "Calculations contained on this website are only estimates based on the details you provided and should be used for general information purposes only.\n" +
  "Comparison Rates provided on this website are based on a loan amount of $150,000 and a term of 25 years. WARNING: Comparison Rates apply only to the examples given. Different loan amounts and terms may result in different Comparison Rates. Costs such as redraw, early repayment or discharge fees are not included in the Comparison Rate. \n" +
  "Information on this website is for general purposes only and does not constitute personal advice. Interest rates, fees and other loan product details shown are based on information provided by the relevant credit provider and subject to change without notice. No responsibility is taken for errors or oversights and you should review the terms and conditions of the loan contract for full details."

const ProductPage = ({ location }) => (
  <Layout subfooter={content}>
    <SEO title="Find home loan products" />
    <Filter location={location} />
  </Layout>
)

export default ProductPage
