import React from "react"
import PropTypes from "prop-types"
import { Card } from "react-bootstrap"
import "./inputError.css"

const InputError = ({ header, message }) => {
  return (
    <>
      <h2 className="error-header">{header}</h2>
      <Card className="error-card text-primary">
        <Card.Body>
            <div className="error-text text-primary">
                {message}
            </div>
        </Card.Body>
      </Card>
    </>
  )
}

InputError.propTypes = {
    header: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
}

export default InputError
