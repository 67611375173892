import React, { useContext, useEffect } from "react"
import { GlobalContext } from "../context/provider.js"
import { Modal } from "react-bootstrap"
import AffiliateLeadForm from "./affiliateLeadForm"
import "./affiliateLeadFormModal.css"

const AffiliateLeadFormModal = ({ location }) => {
  const { state, dispatch } = useContext(GlobalContext)
  useEffect(() => {
    if (sessionStorage.getItem("REDIRECT_FROM_BGL") === "TRUE") {
      dispatch({
        type: "SET_ALL_STATE",
        state: JSON.parse(sessionStorage.getItem("STATE")),
      })
    }
  }, [location.path])

  return (
    <Modal
      centered
      show={state.showAffiliateLeadFormModal}
      onHide={() => {
        dispatch({
          type: "TOGGLE_AFFILIATE_LEAD_FORM_MODAL",
          show: false,
          product: null,
        })
      }}
    >
      <Modal.Header
        closeButton
        className="affiliate-lead-form-header"
        closeLabel=""
      >
        <Modal.Title className="affiliate-lead-form-title text-primary">
          Enquire
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="affiliate-lead-form-body">
        <div className="affiliate-lead-form-instruction">
          Upon receipt of your details, we’ll be in touch asap to discuss your
          loan selection and steps to progress your application.
        </div>
        <AffiliateLeadForm location={location} />
        <div
          className="affiliate-lead-form-cancel text-primary"
          role="button"
          tabIndex="-1"
          onClick={() => {
            dispatch({
              type: "TOGGLE_AFFILIATE_LEAD_FORM_MODAL",
              show: false,
              product: false,
            })
          }}
          onKeyDown={() => {
            dispatch({
              type: "TOGGLE_AFFILIATE_LEAD_FORM_MODAL",
              show: false,
              product: null,
            })
          }}
        >
          &lt; Cancel
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default AffiliateLeadFormModal
