import React, { useContext } from "react"
import { GlobalContext } from "../context/provider.js"
import { Modal, Container, Row, Col } from "react-bootstrap"
import LoanComparisonHeader from "./loanComparisonHeader"
import LoanComparisonProduct from "./loanComparisonProduct"
import "./loanComparisonModal.css"

const LoanComparisonModal = () => {
  const { state, dispatch } = useContext(GlobalContext)

  let hasSavings = false
  const { compareLoans, interestRate } = state

  if (state.refinance) {
    const minRate = compareLoans
      .map(loan => parseFloat(loan.IntRate))
      .reduce((a, b) => Math.min(a, b))
    hasSavings = minRate < interestRate
  }

  return (
    <Modal
      centered
      dialogClassName={
        compareLoans.length <= 1
          ? "loan-comparison-modal-sm"
          : "loan-comparison-modal"
      }
      show={state.showLoanComparisonModal}
      onHide={() =>
        dispatch({ type: "TOGGLE_LOAN_COMPARISON_MODAL", show: false })
      }
    >
      <Modal.Header
        closeButton
        className="loan-comparison-modal-header"
        closeLabel=""
      >
        <Modal.Title className="text-primary loan-comparison-modal-title">
          <strong>Home Loan</strong> comparison
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row
            className={
              compareLoans.length <= 1
                ? "loan-comparison-item-row-sm"
                : "loan-comparison-item-row"
            }
          >
            <Col
              xs={6}
              md={6}
              lg={compareLoans.length <= 2 ? 4 : 3}
              className="loan-comparison-item"
            >
              <LoanComparisonHeader
                loanAmount={Number(state.loanAmount.replaceAll(",", ""))}
                hasSavings={hasSavings}
              />
            </Col>
            <Col
              xs={6}
              md={6}
              lg={compareLoans.length <= 2 ? 4 : 3}
              className="loan-comparison-item"
            >
              <LoanComparisonProduct
                product={state.compareLoans[0]}
                hasSavings={hasSavings}
              />
            </Col>
            {compareLoans.length > 1 && (
              <Col
                xs={6}
                md={6}
                lg={compareLoans.length <= 2 ? 4 : 3}
                className="loan-comparison-item"
              >
                <LoanComparisonProduct
                  product={state.compareLoans[1]}
                  hasSavings={hasSavings}
                />
              </Col>
            )}
            {compareLoans.length > 2 && (
              <Col
                xs={6}
                md={6}
                lg={compareLoans.length <= 2 ? 4 : 3}
                className="loan-comparison-item"
              >
                <LoanComparisonProduct
                  product={state.compareLoans[2]}
                  hasSavings={hasSavings}
                />
              </Col>
            )}
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  )
}

export default LoanComparisonModal
