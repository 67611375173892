import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Card, Container, Row, Col } from "react-bootstrap"
import { calculateRepayment } from "../utils/helpers"
import "./userSavings.css"

const UserSavings = ({ userLoan, rate }) => {
  const { loanAmount, interestRate, loanTerm, isInterestOnly } = userLoan

  const userMonthlyRepayment = calculateRepayment(
    parseFloat(loanAmount),
    interestRate,
    loanTerm * 12,
    isInterestOnly
  )
  const monthlyRepayment = calculateRepayment(
    parseFloat(loanAmount),
    rate,
    loanTerm * 12,
    isInterestOnly
  )
  const monthlySavings = Math.round(userMonthlyRepayment - monthlyRepayment)
  const rateSavings = Math.round((interestRate - rate) * 100) / 100

  return (
    <>
      {rateSavings > 0 && <h2 className="savings-header">You Could Save</h2>}
      {rateSavings <= 0 && <h2 className="savings-header">Nice Rate!</h2>}
      <Card className="savings-card text-primary">
        <Card.Body>
          {rateSavings > 0 && (
            <Container>
              <Row>
                <Col xs={12} md={6} lg={3} className="savings-card-col">
                  <div className="savings-card-primary-text">
                    {rateSavings}%
                  </div>
                  <div className="savings-card-secondary-text">
                    off your rate
                  </div>
                </Col>
                <Col xs={12} md={6} lg={3} className="savings-card-col">
                  <div className="savings-card-primary-text">
                    ${monthlySavings.toLocaleString()}
                  </div>
                  <div className="savings-card-secondary-text">per month</div>
                </Col>
                <Col xs={12} md={6} lg={3} className="savings-card-col">
                  <div className="savings-card-primary-text">
                    ${(12 * monthlySavings).toLocaleString()}
                  </div>
                  <div className="savings-card-secondary-text">per year</div>
                </Col>
                <Col xs={12} md={6} lg={3} className="savings-card-col">
                  <div className="savings-card-primary-text">
                    ${(loanTerm * 12 * monthlySavings).toLocaleString()}
                  </div>
                  <div className="savings-card-secondary-text">your term</div>
                </Col>
              </Row>
              <Row
                style={{ paddingTop: "12px", fontSize: "14px" }}
                className="text-secondary"
              >
                <Col>
                  Potential repayment savings compares your loan against the
                  lowest rate from the options listed below. Information current
                  at time of publishing and subject to change. Please Connect
                  with us to confirm all details and assess loan suitability.
                </Col>
              </Row>
            </Container>
          )}
          {rateSavings <= 0 && (
            <div>
              Looking for a lower rate than advertised?{" "}
              <Link to="/contact" className="text-secondary">
                Contact us
              </Link>{" "}
              about Negotiated Rates - depending on your circumstances we may be
              able to negotiate a lower rate for you.
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  )
}

UserSavings.propTypes = {
  userLoan: PropTypes.object.isRequired,
  rate: PropTypes.number.isRequired,
  loanTerm: PropTypes.number.isRequired,
}

export default UserSavings
